<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="!Object.keys(purchaseSummaries).length"
        small
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        Check-Ins/Print
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Purchase Summary
        </v-toolbar-title>
      </v-toolbar>
      <v-container>
        <ls-purchase-table
          :lot="lot"
        />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import LsPurchaseTable from '@/views/Inventory/CutPlans/components/LsPurchaseTable'
import lotSummaryMixin from '@/views/Inventory/CutPlans/components/lotSummaryMixin'

export default {
  name: 'LsPurchaseTableDialog',
  mixins: [lotSummaryMixin],
  components: {
    LsPurchaseTable
  },
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
