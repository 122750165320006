<template>
  <v-responsive>
    <v-container
      v-if="getNotes"
      id="patch-notes"
    >
      <base-title class="pb-4">
        Patch Notes
      </base-title>
      <!--Results-->
      <div
        class="mb-8"
        v-for="(note, key) in notesToShow"
        :key="key"
      >
        <v-card
          max-width="800"
          outlined
        >
          <v-card-title>
            <span class="subtitle-1">
              {{ note.title }}
            </span>
          </v-card-title>
          <v-card-subtitle class="caption">
            by {{ note.by }} on {{ note.date | formatDate }} <br>
          </v-card-subtitle>
          <v-card-text>
            <vue-markdown>
              {{ note.body }}
            </vue-markdown>
          </v-card-text>
        </v-card>
      </div>
      <v-btn
        color="primary"
        text
        :disabled="showing > getNotes.length"
        @click="showing += 5"
      >
        Show More...
      </v-btn>
    </v-container>
    <p
      v-else
      class="text-center display-1 pb-5"
    >
      No Patch Notes Available
    </p>
  </v-responsive>
</template>

<script>
import { mapGetters } from 'vuex'
import VueMarkdown from 'vue-markdown'
import BaseTitle from '../base/components/BaseTitle'

export default {
  name: 'PatchNotes',
  components: {
    BaseTitle,
    VueMarkdown
  },
  data: () => ({
    showing: 10
  }),
  computed: {
    ...mapGetters('patchNotes', ['getNotes']),
    // applies the showing variable to limit loading
    notesToShow () {
      return this.getNotes.slice(0, this.showing)
    }
  },
  mounted () {
    this.$store.commit('patchNotes/markSeen')
  }
}
</script>

<style>
  #patch-notes img {
    max-width: 650px;
  }
</style>
