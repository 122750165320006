<template>
  <v-list-item
    @click="$router.push({ name: report.route })"
  >
    <v-list-item-content>
      <v-list-item-title>
        <h4>
          {{ report.name }}
          <v-icon
            v-if="report.addedDate && !monthHasPassed(report.addedDate)"
            color="accent"
          >
            mdi-new-box
          </v-icon>
        </h4>
      </v-list-item-title>
      <v-list-item-subtitle>
        <em>
          {{ report.description }}
        </em>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import utils from '@/helpers/utils'

export default {
  name: 'ReportListItem',
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  methods: {
    monthHasPassed (createdDate) {
      return utils.timeHasPassed(createdDate, 1, 'M')
    }
  }
}
</script>
