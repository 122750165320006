<template>
  <v-card class="pb-2">
    <v-card-subtitle>
      Cut Weights
    </v-card-subtitle>
    <v-data-table
      v-if="inventoryLines.length > 0"
      style="cursor: pointer;"
      :headers="headers"
      :items="tableLines"
      disable-pagination
      multi-sort
      hide-default-footer
      @click:row="openEditLineDialog"
    >
      <template v-slot:item.count="{ item }">
        {{ inventoryLines.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.inventory_box_name="{ item }">
        <info-tooltip>
          <template v-slot:wrap-me>
            <router-link-id
              name="inventory-on-hand-box"
              :id="Number(item.inventory_box_id)"
            >
              <span class="text-no-wrap">
                {{ item.inventory_box_name }}
              </span>
            </router-link-id>
          </template>
          #{{ item.inventory_box_id }}
        </info-tooltip>
      </template>
      <template v-slot:item.catalog="{ item }">
        {{ item.catalog | filterObject('name') }}
      </template>
      <template v-slot:item.container="{ item }">
        {{ getTextGivenValue(item.container, containerTypes) }}
      </template>
      <template v-slot:item.gross="{ item }">
        {{ item.gross | formatNumber }} lbs
      </template>
      <template v-slot:item.tare="{ item }">
        {{ item.tare | formatNumber }} lbs
      </template>
      <template v-slot:item.net="{ item }">
        {{ item.gross - item.tare | formatNumber }} lbs
      </template>
      <template v-slot:item.pieces="{ item }">
        {{ item.pieces | formatNumber }}
      </template>
      <template v-slot:body.append>
        <tr class="font-weight-bold">
          <td />
          <td />
          <td />
          <td />
          <td class="text-right">
            {{ inventorySummary.gross | formatNumber }} lbs
          </td>
          <td class="text-right">
            {{ inventorySummary.tare | formatNumber }} lbs
          </td>
          <td class="text-right">
            {{ inventorySummary.net | formatNumber }} lbs
          </td>
          <td class="text-right">
            {{ inventorySummary.pieces | formatNumber }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <div
      class="ml-4 font-weight-thin text-center"
      v-else
    >
      No Inventory Lines have been added. Select the + button to start
    </div>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="canAddLine"
        @click="showAddInventoryDialog = !showAddInventoryDialog"
      >
        Add Line
      </v-btn>
    </v-card-actions>
    <add-inventory-line-dialog
      v-model="showAddInventoryDialog"
      :inventory-id="inventoryId"
      @newLine="$emit('linesUpdated')"
    />
    <edit-inventory-line-dialog
      v-if="editLine"
      v-model="editLineDialog"
      :line="editLine"
      :container-types="containerTypes"
      @updateLine="$emit('linesUpdated')"
    />
  </v-card>
</template>

<script>

import AddInventoryLineDialog from './AddInventoryLineDialog.vue'
import EditInventoryLineDialog from './EditInventoryLineDialog.vue'

export default {
  name: 'InventoryLinesSection',
  components: {
    EditInventoryLineDialog,
    AddInventoryLineDialog
  },
  props: {
    canAddLine: {
      type: Boolean,
      default: false
    },
    containerTypes: {
      type: Array,
      required: true
    },
    inventoryId: {
      type: Number,
      required: true
    },
    inventoryLines: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    headers: [
      { text: '', value: 'count' },
      { text: 'Catalog', value: 'catalog' },
      { text: 'Inventory Box', value: 'inventory_box_name' },
      { text: 'Container', value: 'container' },
      { text: 'Gross', value: 'gross', align: 'right' },
      { text: 'Tare', value: 'tare', align: 'right' },
      { text: 'Net', value: 'net', align: 'right' },
      { text: 'Pieces', value: 'pieces', align: 'right' }
    ],
    editLine: null,
    editLineDialog: false,
    showAddInventoryDialog: false
  }),
  computed: {
    inventorySummary () {
      return this.inventoryLines.reduce((accum, line) => {
        accum.gross += Number(line.gross)
        accum.tare += Number(line.tare)
        accum.net += Number(line.net)
        accum.pieces += Number(line.pieces)
        return accum
      }, {
        gross: 0,
        tare: 0,
        net: 0,
        pieces: 0
      })
    },
    tableLines () {
      return this.inventoryLines.map((line, index) => {
        line.count = index + 1
        return line
      })
    }
  },
  methods: {
    openEditLineDialog (line) {
      this.editLine = line
      this.editLineDialog = true
    },
    deleteLine (line) {
      console.log('deleteLine')
    }
  }
}
</script>
