<!--
datetime-local will emit a UTC time
The localtime computed converters a ISO 8601 to a local time string
-->
<template>
  <div>
    <v-text-field
      :value="localTime"
      type="datetime-local"
      :label="label"
      clearable
      :max="max"
      :min="min"
      @change="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'DateTimeLocal',
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: 'Datetime'
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    }
  },
  computed: {
    localTime () {
      if (!this.value) return null
      // datetime-local expects = 2023-01-01T02:24:00
      const d = new Date(this.value)
      const dateTimeLocalValue = (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, -1)
      return dateTimeLocalValue
    }
  }
}
</script>
