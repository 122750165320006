<template>
  <unauthorized
    :authorized="$store.getters.can('edit-cut-plans')"
    message="Unauthorized to edit cut plans"
  >
    <v-container>
      <url-breadcrumbs
        :items="[
          ['Cut Plan', { name: 'cut-plan-list' }],
          ['Edit', { name: 'cut-plan-edit' }]
        ]"
      />
      <base-title title="Edit Cut Plan" />
      <v-row
        class="px-2 pb-2 ma-0"
        justify="space-between"
      >
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
          <v-select
            label="Check-In Locations"
            :items="locations"
            v-model="ciLocationFilter"
            dense
            @change="resolveFilters"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 2">
          <lot-ordering>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                small
                @click="on"
              >
                Order Cut Plans
              </v-btn>
            </template>
          </lot-ordering>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <cut-plan-purchase-filter />
        </v-col>
      </v-row>
      <v-card
        flat
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-row>
                <h3>
                  Check-Ins that have not been Cut
                </h3>
                <v-spacer />
                <v-btn
                  :disabled="selectedPurchases.length === 0"
                  color="primary"
                  text
                  small
                  @click="multiDialog = true"
                >
                  Open Allocation Dialog
                  <v-icon
                    class="ml-1"
                    small
                  >
                    mdi-open-in-app
                  </v-icon>
                </v-btn>
              </v-row>
              <v-sheet
                outlined
                style="max-height: 75vh"
                class="overflow-y-auto pa-2"
              >
                <purchase-summary
                  v-for="(purchase, index) in getFilteredPurchases"
                  :key="index"
                  :purchase="purchase"
                  :value="selectedPurchases.includes(purchase.id)"
                  @selectInput="v => v ? selectPurchase(purchase) : unselectPurchase(purchase)"
                  @refetchCutPlan="fetchCutPlan"
                />
              </v-sheet>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <h3>Lots that are OPEN or CUTTING</h3>
              <v-sheet
                outlined
                style="max-height: 75vh"
                class="overflow-y-auto pa-2 ma-1"
              >
                <lot-summary
                  class="mb-2"
                  v-for="(lot, index) in getLots"
                  :key="index"
                  :lot="lot"
                />
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <multi-cut-plan-allocation
      v-model="multiDialog"
      :purchases="getPurchases.filter(p => selectedPurchases.includes(p.id))"
    />
  </unauthorized>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PurchaseSummary from './components/PurchaseSummary'
import LotSummary from '@/views/Inventory/CutPlans/components/LotSummary'
import LotOrdering from './components/LotOrdering'
import MultiCutPlanAllocation from '@/views/Inventory/CutPlans/MultiCutPlanAllocation'
import CutPlanPurchaseFilter from '@/views/Inventory/CutPlans/CutPlanPurchaseFilter'

export default {
  name: 'CutPlanEdit',
  components: { CutPlanPurchaseFilter, MultiCutPlanAllocation, LotOrdering, LotSummary, PurchaseSummary },
  data: function () {
    return {
      selectedPurchases: [],
      multiDialog: false,
      dialog: false,
      dialogPurchase: null,
      ciLocationFilter: 'All',
      selectedPurchase: {}
    }
  },
  computed: {
    ...mapGetters('cutPlanStore', [
      'getLots',
      'getPurchases',
      'getFilteredPurchases'
    ]),
    ...mapGetters([
      'converterTypes'
    ]),
    locations () {
      const locs = ['All']
      for (const location of this.$store.getters['getConfigLocations']) {
        locs.push(location.name)
      }
      return locs
    }
  },
  methods: {
    ...mapMutations('cutPlanStore', [
      'setCiLocation'
    ]),
    resolveFilters () {
      this.setCiLocation(this.ciLocationFilter)
    },
    selectPurchase (purchase) {
      this.selectedPurchases.push(purchase.id)
    },
    unselectPurchase (purchase) {
      this.selectedPurchases = this.selectedPurchases.filter(x => x !== purchase.id)
    },
    ...mapActions('cutPlanStore', [
      'fetchCutPlan'
    ])
  },
  async mounted () {
    await this.$store.dispatch('fetchConfig')
    await this.fetchCutPlan()
  }
}
</script>
