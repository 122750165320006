<template>
  <unauthorized
    :authorized="$store.getters.can('view-inventory')"
    message="Unauthorized to view Inventories"
  >
    <base-title title="Inventory List" />

    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
      @advancedOpened="fetchAdvancedSets"
    >
      <date-range-input
        :start-date="lotStartDate"
        :end-date="lotEndDate"
        start-date-label="Start Ship Date"
        end-date-label="End Ship Date"
        @alterStartDate="lotStartDate = $event"
        @alterEndDate="lotEndDate = $event"
      />
      <date-range-input
        :start-date="startDate"
        :end-date="endDate"
        start-date-label="Creation Date From"
        end-date-label="Creation Date To"
        @alterStartDate="startDate = $event"
        @alterEndDate="endDate = $event"
      />
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Lot Name"
            v-model="lotName"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Notes"
            v-model="notes"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Divisions"
            v-model="divisionSet"
            multiple
            :items="divisions"
            item-text="text"
            item-value="value"
          />
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Smelter"
            v-model="smelterSet"
            multiple
            :items="smelters"
            item-text="text"
            item-value="value"
          />
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            label="Lot Status"
            v-model="lotStatusSet"
            multiple
            :items="$store.getters['lotStore/lotStatuses']"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <router-link-id
          :id="item.id"
          name="inventory"
        >
          <v-btn small>
            {{ item.name }}
          </v-btn>
        </router-link-id>
      </template>
      <template v-slot:item.lot__name="{ item }">
        <span v-if="item.lot">
          <router-link
            v-if="item.lot"
            :to="{ name: 'lot', params: { id: item.lot.id } }"
          >
            {{ item.lot.name }}
          </router-link>
        </span>
      </template>
      <template v-slot:item.line_summary="{ item }">
        {{ item.line_summary.net }} lbs
      </template>
      <template v-slot:item.count="{ item }">
        {{ item.inventoryline_set.length }}
      </template>
      <template v-slot:item.lot__status="{ item }">
        <div v-if="item.lot">
          {{ $store.getters['lotStore/lotStatuses'].find(x => x.value === item.lot.status)['text'] }}
        </div>
      </template>
      <template v-slot:item.smelter__name="{ item }">
        {{ item.lot | filterObject('smelter') | filterObject('name') }}
      </template>
      <template v-slot:item.lot__ship_date="{ item }">
        {{ item.lot | filterObject('ship_date') | formatDate }}
      </template>
      <template v-slot:item.division__name="{ item }">
        <span v-if="item.division">
          {{ item.division.name }}
        </span>
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | formatDate }}
      </template>
      <template v-slot:item.cut_plan_color="{ item }">
        <div v-if="item.lot">
          <cut-plan-view-button
            :id="item.lot.id"
            :color="item.cut_plan_color"
          />
        </div>
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
  </unauthorized>
</template>

<script>
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'
import axios from 'axios'
import CutPlanViewButton from '@/components/CutPlanViewButton'

export default {
  mixins: [chipsMixin, paginationMixin],

  name: 'InventoryList',
  components: {
    CutPlanViewButton,
    ResultPagination,
    SearchFilters
  },
  data: function () {
    let additionalHeaders = []
    if (this.$store.getters.can('view-cut-plans')) {
      additionalHeaders.push({ text: 'Cut Plan', value: 'cut_plan_color' })
    }
    return {
      pageName: 'inventory-list',

      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Lot', value: 'lot__name' },
        { text: 'Notes', value: 'notes' },
        { text: 'Net', sortable: false, value: 'line_summary' },
        { text: 'Lines', sortable: false, value: 'count' },
        { text: 'Lot Status', value: 'lot__status' },
        { text: 'Division', value: 'division__name' },
        { text: 'Smelter', value: 'smelter__name' },
        { text: 'Ship Date', value: 'lot__ship_date' },
        { text: 'Created Date', value: 'created_at' },
        ...additionalHeaders
      ],
      // containerTypes
      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'Notes', prop: 'notes', type: String, query: 'notes__icontains' },
        { name: 'Lot Name', prop: 'lotName', type: String, query: 'lot__name__icontains' },
        { name: 'Divisions', prop: 'divisionSet', type: Array, query: 'division__name__in' },
        { name: 'Smelters', prop: 'smelterSet', type: Array, query: 'lot__smelter__name__in' },
        { name: 'Lot Status', prop: 'lotStatusSet', type: Array, query: 'lot__status__in' },
        { name: 'Start Ship', prop: 'lotStartDate', type: String, query: 'lot__ship_date__gte' },
        { name: 'End Ship', prop: 'lotEndDate', type: String, query: 'lot__ship_date__lte' },
        { name: 'Start Date', prop: 'startDate', type: String, query: 'created_at__gte' },
        { name: 'End Date', prop: 'endDate', type: String, query: 'created_at__lte' }
      ]),

      divisions: [],
      smelters: []
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    async executeSearch () {
      const params = this.getQueryParams()
      params.fields = [
        'id',
        'name',
        'lot',
        'notes',
        'division',
        'inventoryline_set',
        'line_summary',
        'cut_plan_color',
        'created_at'
      ].join(',')
      axios.get('/api/inventories/', { params }).then(this.setResponseResults)
    },
    fetchAdvancedSets () {
      if (this.divisions.length === 0) {
        axios.get('/api/divisions/?page_size=0&fields=name').then(response => {
          this.divisions = response.data.map(d => ({ text: d.name, value: d.name }))
        })
      }
      if (this.smelters.length === 0) {
        axios.get('/api/smelters/?page_size=0&fields=id,name').then(r => {
          this.smelters = r.data.map(sm => ({ text: sm.name, value: sm.name }))
        })
      }
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
