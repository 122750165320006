<!--Dashboard loads components dynamically to build the user dashboard-->
<template>
  <v-container>
    <v-row>
      <base-title>
        Dashboard
      </base-title>
    </v-row>
    <v-row>
      <v-col
        v-for="(component, index) in viewableComponents"
        :key="index"
        :cols="component.cols"
      >
        <component :is="component.name" />
      </v-col>
      <v-col v-if="viewableComponents.length === 0">
        <v-card>
          <v-card-text>
            No dashboard components available.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HomeDashboard',
  components: {
    WarehousePieceCounts: () => import('./components/Warehouse/WarehousePieceCounts'),
    // this method of loading components LAZY loads them
    CurrentCutWeights: () => import('./components/CurrentCutWeights/CurrentCutWeights'),
    CutPlan: () => import('./components/CutPlan'),
    CurrentCheckIns: () => import ('./components/AccountManagers/CurrentCheckIns')
  },
  data: function () {
    return {
      // components are dynamically loaded from the following list into a v-row
      components: [
        { name: 'current-cut-weights', can: 'view-cut-weights-dashboard', cols: 12 },
        // { name: 'warehouse-piece-counts', can: 'view-warehouse-piece-counts', cols: 12 }
        { name: 'current-check-ins', can: 'view-current-check-ins', cols: 12 }
      ]
    }
  },
  computed: {
    /**
     * Returns a list of components that pass the 'can' check
     * @returns {{can: string, name: string}|*}
     */
    viewableComponents () {
      return this.components.filter(component => this.$store.getters.can(component.can))
    }
  }
}
</script>
