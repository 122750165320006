<template>
  <ValidationObserver
    v-slot="{ invalid }"
  >
    <edit-dialog
      :value="value"
      headline-text="Add Inventory Line"
      accept-text="Add"
      show-decline
      :inputs-valid="!invalid"
      @accept="addLine()"
      @input="$emit('input', $event)"
    >
      <v-switch
        v-model="useExistingInventoryBox"
        label="Use Existing Inventory Box"
      />
      <ValidationProvider
        v-if="useExistingInventoryBox"
        rules="required"
        v-slot="{ errors }"
      >
        <search-autocomplete
          v-model="inventoryBox"
          :params="{ ordering: 'name', status: 'AC' }"
          label="Inventory Box Search"
          api-path="/api/inventory_boxes/"
          :format-entry="x => Object.assign({}, { friendlyName: '# ' + x.name }, x)"
        />
      </ValidationProvider>
    </edit-dialog>
  </ValidationObserver>
</template>

<script>
import SearchAutocomplete from '../../../base/components/SearchAutocomplete.vue'
import { addInventoryLine } from '../../../requests/lotInventory'

export default {
  name: 'AddInventoryLineDialog',
  components: { SearchAutocomplete },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    inventoryId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    useExistingInventoryBox: false,
    inventoryBox: null
  }),
  watch: {
    value (opened) {
      if (!opened) return
      this.inventoryBox = null
      this.useExistingInventoryBox = false
    }
  },
  methods: {
    /**
     * Calls Inventory/AddLine which allows us to create
     * an empty box or fill from an existing inventory box
     * @returns {Promise<void>}
     */
    async addLine () {
      let args = [this.inventoryId]
      if (this.useExistingInventoryBox) {
        if (!this.inventoryBox) {
          this.$store.commit('setSnackbarError', 'Inventory box is not set!')
          return
        }
        args.push(this.inventoryBox.id)
      }
      const response = await addInventoryLine(...args)
      this.$emit('newLine', response.data)
    }
  }
}
</script>
