import { render, staticRenderFns } from "./ShreddingOperationsView.vue?vue&type=template&id=0b0018a6&"
import script from "./ShreddingOperationsView.vue?vue&type=script&lang=js&"
export * from "./ShreddingOperationsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCalendar,VCol,VMenu,VRow,VSheet,VTextField})
