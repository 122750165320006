<template>
  <v-card>
    <v-card-subtitle>
      Overview
    </v-card-subtitle>
    <v-row>
      <card-list-item
        v-if="inventory.lot"
        title="Lot"
      >
        <router-link :to="{ name: 'lot', params: { id:inventory.lot.id }}">
          {{ `Lot #${inventory.lot.name}` }}
        </router-link>
      </card-list-item>
      <card-list-item
        v-else
        title="Lot"
      >
        No Lot Found
      </card-list-item>
      <card-list-item title="Division">
        {{ inventory.division ? inventory.division.name : '--' }}
      </card-list-item>
    </v-row>
    <v-row>
      <card-list-item title="Created">
        {{ inventory.created_at | formatDate }}
      </card-list-item>
      <card-list-item title="Ship Date">
        {{ inventory.lot | filterObject('ship_date') | formatDate }}
      </card-list-item>
    </v-row>
    <v-row>
      <card-list-item title="Lot Type">
        {{ formatLotType() | filterNA }}
      </card-list-item>
      <card-list-item title="Lot Smelter">
        {{ inventory.lot | filterObject('smelter') | filterObject('name') }}
      </card-list-item>
    </v-row>
    <v-row>
      <card-list-item title="Location">
        {{ inventory | filterObject('location_name') }}
      </card-list-item>
    </v-row>
    <v-row>
      <card-list-item title="Notes">
        {{ inventory.notes | filterNA }}
      </card-list-item>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'InvDetailsSection',
  props: {
    inventory: { type: Object, required: true },
    lotTypes: { type: Array, required: true }
  },
  methods: {
    /**
     * Get the more human readable version of a lot type
     */
    formatLotType () {
      if (!this.inventory.lot) return null
      const type = this.inventory.lot.designation
      if (!type) return null
      for (const obj of this.lotTypes) {
        if (obj.value === type) return obj.text
      }
      return null
    }
  }
}
</script>
