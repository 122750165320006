<template>
  <div>
    <base-title>
      Calendar
      <template
        v-if="$refs.calendar"
        v-slot:subtitle
      >
        {{ $refs.calendar.title }}
      </template>
    </base-title>
    <div>
      <v-row>
        <v-col>
          <event-scopes v-model="selectedScopes" />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
        >
          <calendar-view-select
            class="mb-4"
            v-model="type"
            :prev="() => $refs.calendar.prev()"
            :next="() => $refs.calendar.next()"
            @dayFocus="focus = $event"
          />
          <v-sheet height="700">
            <!--key added as a bug fix to force re-drawing-->
            <v-calendar
              :key="events.length"
              ref="calendar"
              v-model="focus"
              :type="type"
              :events="events"
              :event-color="x => x.color"
              event-text-color="black"
              class="font-weight-bold"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="getEvents"
            />
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
              min-width="300"
              max-width="600"
            >
              <event-card v-model="selectedEvent">
                <h3>
                  Status: {{ selectedEvent.status }}
                </h3>
                <strong>
                  {{ selectedEvent.description }}
                </strong>
              </event-card>
            </v-menu>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
        >
          <v-card
            flat
          >
            <v-card-text>
              <v-chip
                small
                outlined
                class="ma-1 text-no-wrap"
                v-for="(value, index) in $store.getters['calendarStore/keys']"
                :key="index"
              >
                <v-icon
                  class="pr-2"
                  x-small
                  :color="value"
                >
                  mdi-circle
                </v-icon>
                {{ index.toUpperCase() }}
              </v-chip>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CalendarViewSelect from '@/components/Calendar/CalenderViewSelect'
import EventCard from '@/views/Calendar/components/EventCard'
import EventScopes from '@/views/Calendar/components/EventScopes'

export default {
  name: 'CalendarView',
  components: { EventScopes, EventCard, CalendarViewSelect },
  data: () => ({
    selectedOpen: false,
    selectedElement: null,
    selectedEvent: false,
    type: 'month',
    focus: '',
    lastEvent: null,
    selectedScopes: []
  }),
  computed: {
    ...mapGetters('calendarStore', [
      'events'
    ])
  },
  watch: {
    selectedScopes () {
      this.fetchEvents()
    }
  },
  methods: {
    getEvents (event) {
      this.lastEvent = event
      this.fetchEvents()
    },
    fetchEvents () {
      if (!this.lastEvent) {
        this.$store.commit('setSnackbarError', 'Unable to get dates for calendar update.')
        return
      }
      this.$store.dispatch('calendarStore/fetchEvents', {
        startDate: this.lastEvent.start.date,
        endDate: this.lastEvent.end.date,
        scope: this.selectedScopes.join(',')
      })
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    showEvent ({ nativeEvent, event }) {
      this.selectedElement = nativeEvent.target
      this.selectedEvent = event
      setTimeout(() => {
        this.selectedOpen = true
      }, 10)
    }
  },
  mounted () {
    this.$store.commit('calendarStore/clearCache')
  }
}
</script>
