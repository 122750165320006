import axios from 'axios'

export function fetchInventory (id) {
  return axios.get(`/api/inventories/${id}/`)
}

export function fetchInventoryLines (params) {
  return axios.get('/api/inventory_lines/', { params })
}

export function fetchInventorySetups () {
  return axios.get('/api/inventory_line_setups/?page_size=0')
}

export function addInventoryLine (inventoryId, boxId) {
  return axios.post(`/api/inventories/${inventoryId}/add_line/`, { box_id: boxId })
}

export function patchInventoryLine (lineId, payload) {
  return axios.patch(`/api/inventory_lines/${lineId}/`, payload)
}

/**
 * Removes an inventory Line.
 * @param lineId
 * @param payload
 * @returns {Promise<AxiosResponse<any>>}
 */
export function removeInventoryLine (lineId) {
  return axios.delete(`/api/inventory_lines/${lineId}/`)
}
