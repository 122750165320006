<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        Edit
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit Cut Plan</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-card-text>
          <date-time-local
            v-model="shredStartDateTime"
            :max="shredEndDateTime"
            label="Shredding Started"
          />
          <v-spacer />
          <date-time-local
            v-model="shredEndDateTime"
            :min="shredStartDateTime"
            label="Shredding Finished"
          />
          <div>
            <v-file-input
              v-model="intakeTallySheet"
              accept="image/*,.pdf"
              prepend-icon="mdi-image"
              :label="(lot.tally_sheet) ? 'Replace Tally Sheet' : 'Upload Tally Sheet'"
              persistent-hint
              hint="Accepts image files"
            />
          </div>
          <v-expand-transition>
            <div v-show="mayBeFinalized">
              <v-divider class="mt-8" />
              <v-checkbox
                v-model="finalizeShredding"
                label="Mark Shredding Finalized"
                persistent-hint
                hint="Finalizing Shredding will move the lot's status to cut and remove it from the cut plan list."
              />
            </div>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions class="d-block text-right">
          <v-btn
            text
            class="error--text"
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            :loading="loading"
            @click="updateLot"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import DateTimeLocal from '@/components/DateTimeLocal'

export default {
  name: 'CutPlanEditDialog',
  components: { DateTimeLocal },
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    loading: false,
    shredStartDateTime: null,
    shredEndDateTime: null,
    intakeTallySheet: null,
    initialShredStartDateTime: null,
    finalizeShredding: false
  }),
  computed: {
    mayBeFinalized () {
      return (this.intakeTallySheet || this.lot.tally_sheet) &&
        this.shredEndDateTime &&
        this.shredStartDateTime
    }
  },
  methods: {
    async updateLot () {
      const formData = new FormData()
      if (this.shredStartDateTime) {
        formData.append('shredding_started_date_time', new Date(this.shredStartDateTime).toISOString())
      } else {
        formData.append('shredding_started_date_time', '')
      }
      if (this.shredEndDateTime) {
        formData.append('shredding_completed_date_time', new Date(this.shredEndDateTime).toISOString())
      } else {
        formData.append('shredding_completed_date_time', '')
      }
      if (this.intakeTallySheet) formData.append('tally_sheet', this.intakeTallySheet)
      if (this.finalizeShredding) formData.append('shredding_finalized', this.finalizeShredding)
      try {
        this.loading = true
        await axios.patch(`/api/lots/${this.lot.id}/`, formData)
        this.dialog = false
        this.$store.commit('setSnackbarSuccess', 'Lot successfully updated')
        this.$emit('updated')
      } catch {
        this.$store.commit('setSnackbarError', 'An error occured while updating the lot through the warehouse editor.')
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    if (this.lot.shredding_started_date_time) this.shredStartDateTime = this.lot.shredding_started_date_time
    if (this.lot.shredding_completed_date_time) this.shredEndDateTime = this.lot.shredding_completed_date_time
    if (this.lot.shredding_finalized) this.finalizeShredding = this.lot.shredding_finalized
  }
}
</script>
