import { mapGetters } from 'vuex'

export default {
  props: {
    lot: {
      type: Object,
      required: true,
      notes: ''
    }
  },
  computed: {
    headers () {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Notes', value: 'ciNotes' },
        ...this.converterTypes.map(x => ({ text: x.text, value: x.text }))
      ]
    },
    ...mapGetters('cutPlanStore', [
      'getPurchaseCache',
      'converterTypesIndex',
      'getColorSwatches'
    ]),
    ...mapGetters({
      converterTypes: 'getConverterTypes'
    }),
    lotColor () {
      let found = this.getColorSwatches.find(h => h.hex[0] === this.lot.cut_plan_color)
      if (found) return found
      return { hex: [''], name: 'No color' }
    },
    hiddenPurchases () {
      return Object.keys(this.purchaseSummaries).filter(key => !this.getPurchaseCache[key]).length
    },
    allocations () {
      const allocations = this.$store.getters['cutPlanStore/getAllocations']
      return Object.values(allocations)
    },
    applicableAllocations () {
      // get the purchase urls of allocations (purchase lots) that match the current lot
      let purchaseUrls = this.allocations.filter(a => a.lot === this.lot.url).map(a => a.purchase)
      // only return the allocations the match the purchase urls
      return this.allocations.reduce((accu, ca) => {
        if (purchaseUrls.includes(ca.purchase)) accu.push(ca)
        return accu
      }, [])
    },
    purchaseSummaries () {
      const purchases = {}
      this.applicableAllocations.forEach(allocation => {
        if (!purchases[allocation.purchase]) {
          purchases[allocation.purchase] = []
        }
        if (this.converterTypesIndex[allocation.converter_type]) {
          purchases[allocation.purchase].push({
            'material': this.converterTypesIndex[allocation.converter_type],
            'lot': allocation.lot_display,
            'color': allocation.lot_color
          })
        }
      })
      return purchases
    }
  },
  methods: {
    createSummaryTable (summaries) {
      let collection = []
      let purchase
      for (const index in summaries) {
        purchase = this.getPurchaseCache[index]
        if (!purchase) continue
        let row = purchase.check_ins_details.reduce((accu, ci) => {
          let checkInValues = {
            id: (purchase.is_uncut) ? ci.id : `*${ci.id}`,
            name: ci.yard_name,
            boxCount: ci.box_count,
            ciNotes: ci.cut_plan_notes
          }
          summaries[index].forEach(t => {
            // let colorObj = this.getColorSwatches.find(h => h.hex[0] === t.color)
            checkInValues[t.material] = t.lot === this.lot.name ? 'x' : t.lot
          })
          accu.push(checkInValues)
          return accu
        }, [])
        collection.push(...row)
      }
      return collection
    }
  }
}
