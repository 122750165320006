<template>
  <div>
    <event-scope-button v-model="buttons.trips">
      Trips
    </event-scope-button>
    <event-scope-button v-model="buttons.transit">
      Transit
    </event-scope-button>
  </div>
</template>

<script>
import EventScopeButton from '@/views/Calendar/components/EventScopeButton'
export default {
  name: 'EventScopes',
  components: { EventScopeButton },
  props: {
    value: {
      type: Array,
      default: Array
    }
  },
  data: () => ({
    buttons: {
      trips: true,
      transit: true
    }
  }),
  watch: {
    buttons: {
      handler (buttons) {
        let scopeArr = []
        for (const key of Object.keys(buttons)) {
          if (buttons[key]) scopeArr.push(key)
        }
        this.$emit('input', scopeArr)
      },
      deep: true
    }
  },
  methods: {
    exportAsArray (object) {

    }
  }
}
</script>
