<template>
  <div>
    <v-data-table
      v-if="Object.keys(purchaseSummaries).length"
      :headers="headers"
      :items="createSummaryTable(purchaseSummaries)"
      :hide-default-footer="Object.keys(purchaseSummaries).length <= 5"
      :items-per-page="5"
      mobile-breakpoint="0"
    >
      <template v-slot:top>
        <div class="pr-2 text-right">
          <v-btn
            class="mx-4"
            x-small
            elevation="0"
            color="primary"
            :to="{ name: 'reallocate-purchases', params: { id: lot.id } }"
          >
            Reallocate
          </v-btn>
          <pdf-make-btn
            include-pgm-header
            title="Print lot purchase summaries"
            :doc-def-builder="pdfDoc"
            landscape
          >
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                color="primary"
                elevation="0"
                @click="on"
              >
                print check-ins
              </v-btn>
            </template>
          </pdf-make-btn>
        </div>
      </template>
      <template v-slot:item.ciNotes="{ item }">
        <h5 class="red--text text--darken-3">
          {{ item.ciNotes }}
        </h5>
      </template>
    </v-data-table>
    <div
      v-else
      class="ml-4 mt-8"
    >
      <p class="mb-0">
        No data available
      </p>
    </div>
  </div>
</template>

<script>
import lotSummaryMixin from '@/views/Inventory/CutPlans/components/lotSummaryMixin'
import PdfMakeBtn from '@/base/components/PdfMakeBtn'
import { lotPurchasesSummary } from '@/helpers/pdfMake/lotPurchasesSummary'

export default {
  components: { PdfMakeBtn },
  mixins: [lotSummaryMixin],
  name: 'LsPurchaseTable',
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  methods: {
    pdfDoc () {
      return lotPurchasesSummary(
        this.headers,
        this.createSummaryTable(this.purchaseSummaries),
        `${this.lot.name} ${this.lot.lot_type_display} - ${this.lotColor.name}`,
        this.lot.cut_plan_notes
      )
    }
  }
}
</script>
