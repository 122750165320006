<template>
  <parent-tabs :tabs="filterTabs()" />
</template>

<script>
export default {
  name: 'InventoryParent',
  methods: {
    filterTabs () {
      return this.pageTabs.filter(tab => tab.can)
    }
  },
  computed: {
    pageTabs () {
      const can = this.$store.getters.can
      const useShort = this.$vuetify.breakpoint.smAndDown
      return [
        { id: 1, title: 'List', route: '/inventory-list', can: can('view-inventory') },
        { id: 2, title: useShort ? 'Plan' : 'Cut Plan', route: '/cut-plan-list', can: can('view-cut-plans') },
        { id: 3, title: useShort ? 'Shredding' : 'Shredding Operations', route: '/shredding-operations', can: can('view-cut-plans') }
      ]
    }
  }
}
</script>
