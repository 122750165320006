<template>
  <v-card
    flat
  >
    <v-toolbar
      :color="value.color"
      dense
      flat
    >
      <v-toolbar-title
        :class="getTextClasses(value.color)"
      >
        <span v-if="value.type === 'note'">
          Note #{{ value.id }}
        </span>
        <span v-else>
          {{ value.name }}
        </span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <slot />
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="value.type === 'note'"
        :disabled="!$store.getters.can('edit-cut-plans')"
        color="accent"
        text
        @click="$emit('deleteNote', value.id)"
      >
        delete
      </v-btn>
      <v-spacer />
      <v-btn
        @click="$emit('close')"
        text
      >
        close
      </v-btn>
      <v-btn
        v-if="value.to"
        color="primary"
        elevation="0"
        :to="value.to"
      >
        Open
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { getFriendlyTextClasses } from '@/helpers/utils'

export default {
  name: 'EventCard',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  methods: {
    getTextClasses (color) {
      return getFriendlyTextClasses(color)
    }
  }
}
</script>
