<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    />
    <edit-dialog
      headline-text="Reorder Cut Plan"
      v-model="dialog"
      @accept="saveLotOrder"
    >
      <div>
        Drag the cut plan lines into the order you wish them to be displayed.
      </div>
      <v-list>
        <draggable
          v-model="lots"
          group="people"
        >
          <transition-group>
            <v-list-item
              class="elevation-2 mb-2 rounded"
              v-for="(lot, index) in lots"
              :key="lot.url"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ index + 1 }}. {{ lot.name }}
                  <v-icon :color="lot.cut_plan_color">
                    mdi-square
                  </v-icon>
                </v-list-item-title>
                {{ lot.cut_plan_notes }}
              </v-list-item-content>
            </v-list-item>
          </transition-group>
        </draggable>
      </v-list>
    </edit-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LotOrdering',
  data: () => ({
    dialog: false,
    lots: []
  }),
  watch: {
    dialog (opened) {
      if (opened) {
        this.lots = this.getLots
      }
    }
  },
  computed: {
    ...mapGetters('cutPlanStore', [
      'getLots'
    ])
  },
  methods: {
    ...mapActions('cutPlanStore', [
      'updateLot'
    ]),
    /**
     * Change the order of only entries that have been updated
     */
    saveLotOrder () {
      let changes = []
      for (let key in this.lots) {
        let lot = this.lots[key]
        let order = 1 + Number(key)
        if (lot.cut_plan_order !== order) {
          changes.push(this.updateLot({
            lotUrl: lot.url,
            payload: { cut_plan_order: order }
          }))
        }
      }
      Promise.all(changes).then(() => {
        this.$store.commit('setSnackbarInfo', 'Lot ordering has been applied.')
      })
    }
  }
}
</script>
