<template>
  <ValidationObserver
    v-slot="{ invalid }"
    ref="validationObserver"
  >
    <v-form @submit.prevent>
      <edit-dialog
        :value="value"
        headline-text="Edit Inventory Line"
        accept-text="Save"
        show-decline
        @accept="saveLine"
        @input="$emit('input', $event)"
      >
        <div class="py-2" />
        <div>
          <validation-provider
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              :loading="catalogOptionsLoading"
              v-model="catalogId"
              :items="catalogOptions"
              label="Material"
              :error-messages="errors"
              dense
            />
          </validation-provider>
          <validation-provider
            rules="required"
            v-slot="{ errors }"
          >
            <v-select
              v-model="container"
              label="Container"
              :items="containerTypes"
              :error-messages="errors"
              dense
            />
          </validation-provider>
        </div>
        <div class="d-flex justify-space-between">
          <rules-text
            validation-rules="required|max_value:99999999|decimal:2"
            v-model="gross"
            label="Gross"
            hint="in pounds"
            type="number"
            dense
          />
          <rules-text
            validation-rules="required|max_value:99999999|decimal:2|min_value:0"
            v-model="tare"
            label="Tare"
            hint="in pounds"
            type="number"
            dense
          />
          <rules-text
            validation-rules="required|max_value:99999999|decimal:2|min_value:0"
            v-model="net"
            label="Net"
            hint="in pounds"
            type="number"
            readonly
            dense
          />
        </div>
        <!--get the total pieces-->
        <rules-text
          validation-rules="required|max_value:99999999|decimal:0|min_value:0"
          v-model="pieces"
          label="Pieces"
          type="number"
        />
        <template v-slot:actionBtn>
          <v-btn
            outlined
            @click="removeLine"
          >
            Remove
          </v-btn>
        </template>
      </edit-dialog>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { fetchInventorySetups, patchInventoryLine, removeInventoryLine } from '../../../requests/lotInventory'
import RulesText from '../../../components/ValidationFields/RulesText.vue'

export default {
  name: 'EditInventoryLineDialog',
  components: { RulesText },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    line: {
      type: Object,
      required: true
    },
    containerTypes: {
      type: Array,
      default: Array
    }
  },
  data: () => ({
    useExistingInventoryBox: false,
    inventoryBox: null,
    catalogOptions: null,
    catalogOptionsLoading: false,

    containerType: null,
    catalogId: null,
    container: null,
    gross: 0,
    tare: 0,
    net: 0,
    pieces: 0,
    notes: ''
  }),
  watch: {
    value: {
      handler (opened) {
        if (opened) {
          if (this.line) {
            this.container = this.line.container
            this.gross = Number(this.line.gross)
            this.tare = Number(this.line.tare)
            this.net = Number(this.line.net)
            this.pieces = Number(this.line.pieces)
            this.notes = this.line.notes
            if (this.line.catalog) {
              this.catalogId = this.line.catalog.id
            }
          }
          if (this.catalogOptions === null) this.fetchCatalogOptions()
        }
      },
      immediate: true
    },
    gross (gross) {
      this.updateTare(gross, this.tare)
    },
    tare (tare) {
      this.updateTare(this.gross, tare)
    }
  },
  methods: {
    removeLine () {
      if (confirm('Are you sure you want to remove this line from this lot?')) {
        removeInventoryLine(this.line.id).then(() => {
          this.$emit('updateLine')
          this.$emit('input', false)
          this.$store.commit('setSnackbarInfo', `Inventory Box ${this.line.name} detached`)
        })
      }
    },
    updateTare (gross, tare) {
      this.net = Number(gross) - Number(tare)
    },
    async saveLine () {
      const payload = {
        catalog_id: this.catalogId,
        container: this.container,
        gross: this.gross,
        tare: this.tare,
        net: this.net,
        pieces: this.pieces,
        notes: this.notes
      }
      await patchInventoryLine(this.line.id, payload)
      this.$emit('updateLine')
      this.$emit('input', false)
    },
    fetchCatalogOptions () {
      this.catalogOptionsLoading = true
      fetchInventorySetups().then(response => {
        this.catalogOptions = response.data.map(option => ({
          text: option.catalog.name,
          value: option.catalog.id
        }))
      }).finally(() => {
        this.catalogOptionsLoading = false
      })
    }
  }
}
</script>
