<template>
  <div>
    <v-select
      v-model="selected"
      :items="templates"
      item-text="name"
      label="Use a Template"
      :loading="loading"
      @focus="() => loadTemplates(false)"
      @change="selectTemplate"
      return-object
      append-icon="mdi-refresh"
      :append-outer-icon="selected && selected.id ? 'mdi-pencil-outline' : 'mdi-plus'"
      @click:append="() => loadTemplates(true)"
      @click:append-outer="openEditDialog"
    />
    <text-template-edit-dialog
      v-model="editDialog"
      :default-scope="scopeCode"
      :template="selectedEditable"
    />
  </div>
</template>

<script>
import axios from 'axios'
import TextTemplateEditDialog from '@/components/textTemplates/TextTemplateEditDialog'

export default {
  name: 'TextTemplateSelect',
  components: { TextTemplateEditDialog },
  props: {
    scopeCode: {
      type: String,
      default: null,
      required: false
    },
    currentValue: {
      type: String,
      default: null,
      required: false
    }
  },
  computed: {
    selectedEditable () {
      const x = Object.assign({}, this.selected)
      if (x.name === 'Current Value*') x.name = ''
      return x
    }
  },
  data: () => ({
    editDialog: false,
    selected: null,
    templates: [],
    loading: false,
    fetched: false,
    currentValueCache: ''
  }),
  mounted () {
    this.currentValueCache = this.currentValue
  },
  methods: {
    selectTemplate (selected) {
      const text = (selected && selected.text) ? selected.text : ''
      this.$emit('change', text)
    },
    openEditDialog () {
      this.editDialog = true
    },
    async loadTemplates (force = false) {
      if (force || !this.fetched) {
        this.fetched = true
        this.loading = true
        const params = { page_size: 0 }
        if (this.scopeCode) params.scope = this.scopeCode
        const response = await axios.get('/api/text_templates/', { params })
        if (response.status === 200) {
          this.templates = response.data
          if (this.currentValueCache) {
            this.templates.unshift({ name: 'Current Value*', text: this.currentValueCache })
          }
        } else {
          this.$store.commit('setSnackbarError', 'Failed to retrieve Scopes.')
        }
        this.loading = false
      }
    }
  }
}
</script>
