var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pb-2"},[_c('v-card-subtitle',[_vm._v(" Cut Weights ")]),(_vm.inventoryLines.length > 0)?_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.tableLines,"disable-pagination":"","multi-sort":"","hide-default-footer":""},on:{"click:row":_vm.openEditLineDialog},scopedSlots:_vm._u([{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.inventoryLines.indexOf(item) + 1)+" ")]}},{key:"item.inventory_box_name",fn:function(ref){
var item = ref.item;
return [_c('info-tooltip',{scopedSlots:_vm._u([{key:"wrap-me",fn:function(){return [_c('router-link-id',{attrs:{"name":"inventory-on-hand-box","id":Number(item.inventory_box_id)}},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.inventory_box_name)+" ")])])]},proxy:true}],null,true)},[_vm._v(" #"+_vm._s(item.inventory_box_id)+" ")])]}},{key:"item.catalog",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("filterObject")(item.catalog,'name'))+" ")]}},{key:"item.container",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTextGivenValue(item.container, _vm.containerTypes))+" ")]}},{key:"item.gross",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.gross))+" lbs ")]}},{key:"item.tare",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.tare))+" lbs ")]}},{key:"item.net",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.gross - item.tare))+" lbs ")]}},{key:"item.pieces",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.pieces))+" ")]}},{key:"body.append",fn:function(){return [_c('tr',{staticClass:"font-weight-bold"},[_c('td'),_c('td'),_c('td'),_c('td'),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.inventorySummary.gross))+" lbs ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.inventorySummary.tare))+" lbs ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.inventorySummary.net))+" lbs ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.inventorySummary.pieces))+" ")])])]},proxy:true}],null,false,365655891)}):_c('div',{staticClass:"ml-4 font-weight-thin text-center"},[_vm._v(" No Inventory Lines have been added. Select the + button to start ")]),_c('v-card-actions',[_c('v-spacer'),(_vm.canAddLine)?_c('v-btn',{on:{"click":function($event){_vm.showAddInventoryDialog = !_vm.showAddInventoryDialog}}},[_vm._v(" Add Line ")]):_vm._e()],1),_c('add-inventory-line-dialog',{attrs:{"inventory-id":_vm.inventoryId},on:{"newLine":function($event){return _vm.$emit('linesUpdated')}},model:{value:(_vm.showAddInventoryDialog),callback:function ($$v) {_vm.showAddInventoryDialog=$$v},expression:"showAddInventoryDialog"}}),(_vm.editLine)?_c('edit-inventory-line-dialog',{attrs:{"line":_vm.editLine,"container-types":_vm.containerTypes},on:{"updateLine":function($event){return _vm.$emit('linesUpdated')}},model:{value:(_vm.editLineDialog),callback:function ($$v) {_vm.editLineDialog=$$v},expression:"editLineDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }