<!--This is a list item-->
<template>
  <v-sheet>
    <v-list-item
      class="mb-2 elevation-2"
    >
      <v-list-item-content>
        <v-row>
          <v-col
            cols="9"
            lg="10"
          >
            <strong
              v-for="ci in purchase.check_ins_details"
              :key="ci.index"
            >
              {{ `${ci.id} ${ci.yard_name} - ${ci.current_location}` }} <v-spacer />
              <h5 class="blue--text text--darken-3 mb-2">
                {{ ci.cut_plan_notes }}
              </h5>
            </strong>
          </v-col>
          <v-col
            v-if="value !== null"
            cols="3"
            lg="2"
            class="text-right"
          >
            <v-btn
              icon
              x-small
              @click.prevent="editingPurchase = true"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              small
              icon
              elevation="0"
              @click.prevent="$emit('selectInput', !value)"
            >
              <v-icon
                v-show="value"
              >
                mdi-checkbox-marked-outline
              </v-icon>
              <v-icon
                v-show="!value"
              >
                mdi-checkbox-blank-outline
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <p
          v-if="allocationStatuses[1]"
          class="red--text"
        >
          <strong>
            {{ allocationStatuses[1].map(x => x.text).join(' / ') }}
          </strong>
        </p>
        <v-list
          rounded
          class="pa-1"
        >
          <v-list-item
            dense
            v-for="(line, index) in allocationStatuses[0]"
            :key="'hedge-line-' + index"
          >
            <v-list-item-content>
              <strong v-if="getLotCache[line[0]]">
                {{ getLotCache[line[0]].name }}
                <v-icon :color="getLotCache[line[0]].cut_plan_color">
                  mdi-square
                </v-icon>
              </strong>
              <strong v-else>
                {{ line[0] }}
              </strong>
              {{ line[1].map(n => n.text).join(', ') }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-item-content>
    </v-list-item>
    <edit-dialog
      v-model="editingPurchase"
      headline-text="Cut plan notes"
      @accept="updateCheckIns"
    >
      <div
        v-for="c in checkIns"
        :key="c.id"
      >
        <v-textarea
          v-model="c.changedNote"
          :label="c.id.toString()"
        />
      </div>
    </edit-dialog>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PurchaseSummary',
  props: {
    purchase: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      default: null
    }
  },
  data: () => ({
    editingPurchase: false,
    checkIns: []
  }),
  watch: {
    editingPurchase: {
      handler (opened) {
        if (opened) {
          this.checkIns = this.purchase.check_ins_details.map(c => ({ ...c, changedNote: c.cut_plan_notes }))
        }
      }
    }
  },
  methods: {
    ...mapActions({
      updateCheckIn: 'checkIn/updateCheckIn'
    }),
    async updateCheckIns () {
      let changed = this.checkIns.filter(c => c.cut_plan_notes !== c.changedNote)
      if (changed.length > 0) {
        const promises = this.checkIns.map(c => this.updateCheckIn({ id: c.id, formData: { cut_plan_notes: c.changedNote } }))
        await Promise.all(promises).then(() => {
          this.$emit('refetchCutPlan')
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      converterTypes: 'getConverterTypes'
    }),
    ...mapGetters('cutPlanStore', [
      'getLotCache'
    ]),
    /**
     * Gets the curated list of allocations
     * @returns {any[]}
     */
    allocations () {
      const allocations = this.$store.getters['cutPlanStore/getAllocations']
      return Object.values(allocations).filter(e => e.purchase === this.purchase.url)
    },
    allocationStatuses () {
      let unallocated = []
      const hedges = new Map()
      this.converterTypes.forEach(type => {
        const detail = this.allocations.find(al => al.converter_type === type.value)
        if (!detail) {
          unallocated.push(type)
          return
        }
        const url = detail.lot
        if (hedges.has(url)) {
          hedges.get(url).push(type)
        } else {
          hedges.set(url, [type])
        }
      })
      return [Array.from(hedges.entries()), unallocated]
    }
  }
}
</script>
