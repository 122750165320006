<template>
  <unauthorized
    :authorized="$store.getters.can('view-cut-plans')"
    message="Unauthorized to view cut plans"
  >
    <v-container>
      <base-title>
        Cut Plan
      </base-title>
      <v-card-actions class="pa-0">
        <v-checkbox
          class="pt-2"
          label="Group by lot"
          v-model="groupByLot"
        />
        <v-spacer />
        <v-btn
          small
          v-if="$store.getters.can('edit-cut-plans')"
          :disabled="!$store.getters.can('edit-cut-plans')"
          color="primary"
          :to="{ name: 'cut-plan-edit' }"
        >
          Edit
        </v-btn>
      </v-card-actions>
      <cut-plan-purchase-filter class="mb-4" />
      <div
        v-if="groupByLot"
      >
        <h4 class="mb-4">
          Lot cut plans are ordered by cut priority
        </h4>
        <lot-summary-dense
          class="mb-1"
          v-for="(lot, index) in getLots"
          :key="index"
          :lot="lot"
          @updated="$store.dispatch('cutPlanStore/fetchCutPlan')"
        />
      </div>
      <v-data-table
        v-else-if="Object.keys(purchaseSummaries).length"
        dense
        :headers="headers"
        :items="createSummaryTable(purchaseSummaries)"
        :hide-default-footer="Object.keys(purchaseSummaries).length <= 5"
      >
        <template v-slot:item.notes="{ item }">
          <h5 class="red--text text--darken-3">
            {{ item.notes }}
          </h5>
        </template>
      </v-data-table>
      <p
        v-else
        class="body-1"
      >
        There are no purchase summaries
      </p>
    </v-container>
  </unauthorized>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import LotSummaryDense from './components/LotSummaryDense'
import CutPlanPurchaseFilter from '@/views/Inventory/CutPlans/CutPlanPurchaseFilter'

export default {
  name: 'CutPlanList',
  data: () => ({
    groupByLot: true
  }),
  components: { CutPlanPurchaseFilter, LotSummaryDense },
  computed: {
    ...mapGetters('cutPlanStore', [
      'getLots',
      'getPurchaseCache',
      'converterTypesIndex',
      'getColorSwatches'
    ]),
    ...mapGetters({
      converterTypes: 'getConverterTypes'
    }),
    headers () {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Notes', value: 'notes' },
        { text: 'Location', value: 'location' },
        ...this.converterTypes.map(x => ({ text: x.text, value: x.text }))
      ]
    },
    allocations () {
      const allocations = this.$store.getters['cutPlanStore/getAllAllocations']
      return Object.values(allocations)
    },
    purchaseSummaries () {
      const purchases = {}
      this.allocations.forEach(allocation => {
        if (!purchases[allocation.purchase]) {
          purchases[allocation.purchase] = []
        }
        if (this.converterTypesIndex[allocation.converter_type]) {
          purchases[allocation.purchase].push({
            'material': this.converterTypesIndex[allocation.converter_type],
            'lot': allocation.lot_display,
            'color': allocation.lot_color
          })
        }
      })
      return purchases
    }
  },
  methods: {
    ...mapActions('cutPlanStore', [
      'fetchCutPlan'
    ]),
    createSummaryTable (summaries) {
      let collection = []
      let purchase
      for (const index in summaries) {
        purchase = this.getPurchaseCache[index]
        if (!purchase) continue
        let row = purchase.check_ins_details.reduce((accu, ci) => {
          let checkInValues = {
            id: ci.id,
            name: ci.yard_name,
            notes: ci.cut_plan_notes,
            location: ci.current_location ? ci.current_location : ''
          }
          summaries[index].forEach(t => {
            let colorObj = this.getColorSwatches.find(h => h.hex[0] === t.color)
            checkInValues[t.material] = colorObj ? `${t.lot} - ${colorObj.name}` : t.lot
          })
          accu.push(checkInValues)
          return accu
        }, [])
        collection.push(...row)
      }
      return collection
    }
  },
  mounted () {
    this.fetchCutPlan()
  }
}
</script>
