/**
 * Universally adapts any datatable information into something that can be used with PDF MAKE
 * Generates pdfmake-compatible table data for lot purchase summaries
 * @param { Array } headers table headers formatted for v-data-table headers prop
 * @param { Array } items data formatted for v-data-table items prop
 * @constructor
 */
class DataTablePdfMakeAdaptor {
  constructor (headers, items, widths) {
    this.headers = headers
    this.items = items
    this.widths = widths
  }

  __getHeaders () {
    return this.headers.map(header => header.text)
  }

  __getBody () {
    return this.items.map(i => {
      return this.headers.map(h => i[h.value] ? i[h.value] : '')
    })
  }

  /**
   * Returns a DocDef compatible Table
   * @return {[{}]}
   */
  getTable () {
    let table = {
      style: 'table',
      table: {
        body: [this.__getHeaders(), ...this.__getBody()]
      }
    }
    if (this.widths) table.table.widths = this.widths
    return [table]
  }
}

export default DataTablePdfMakeAdaptor
