<!--v-model the "type" of calendar
<calendar-view-select
  v-model="type"
  :prev="() => $refs.calendar.prev()"
  :next="() => $refs.calendar.next()"
  @dayFocus="focus = $event"
/>
-->
<template>
  <v-sheet class="d-flex">
    <v-btn
      :class="{ 'text-decoration-underline': value === 'month' }"
      small
      text
      @click="$emit('input', 'month')"
    >
      Month
    </v-btn>
    <v-btn
      :class="{ 'text-decoration-underline': value === 'week' }"
      small
      text
      @click="$emit('input', 'week')"
    >
      Week
    </v-btn>
    <v-btn
      :class="{ 'text-decoration-underline': value === 'day' }"
      small
      text
      @click="$emit('input', 'day')"
    >
      Day
    </v-btn>
    <v-spacer />
    <div>
      <v-btn
        small
        text
        @click="$emit('dayFocus', '')"
      >
        Today
      </v-btn>
      <v-btn
        v-if="prev"
        small
        icon
        @click="prev()"
      >
        <v-icon>mdi-arrow-left-bold</v-icon>
      </v-btn>
      <v-btn
        v-if="next"
        small
        icon
        @click="next()"
      >
        <v-icon>mdi-arrow-right-bold</v-icon>
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'CalendarViewSelect',
  props: {
    value: {
      type: String,
      default: 'month'
    },
    prev: {
      type: Function,
      default: null
    },
    next: {
      type: Function,
      default: null
    }
  }
}
</script>
