<!--Displays alphabetical list of all available pages-->
<template>
  <v-container fluid>
    <h1 class="headline text-center">
      Index
    </h1>
    <div
      v-for="letter in alphabet()"
      :key="letter"
    >
      <v-row>
        <v-col
          v-if="alphaExists(letter)"
        >
          <v-card>
            <v-card-title>
              {{ letter }}
            </v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item
                  v-for="(x, i) in alphaRoutes(letter)"
                  :key="i"
                >
                  <router-link
                    :to="{ path: x.path }"
                    :style="{ 'text-transform': 'capitalize' }"
                  >
                    {{ x.name }}
                  </router-link>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Index',
  data: function () {
    return {
      items: [],
      index: []
    }
  },
  computed: {
    ...mapGetters(['can'])
  },
  methods: {
    /**
     * create A thru Z
     * @returns {[]}
     */
    alphabet () {
      let alpha = []
      for (let x = 65; x <= 90; x++) alpha.push(String.fromCharCode(x))
      return alpha
    },
    alphaExists (letter) {
      return !!this.index.find(i => i.name[0] === letter.toLowerCase())
    },
    /**
     * Retrieves index items that match the first character
     */
    alphaRoutes (letter) {
      return this.index.filter(i => i.name[0] === letter.toLowerCase())
    },
    /**
     * pushing items to index, reformatting name
     * for
     * skip if not CAN
     * reformat name
     * add to index
     */
    nameConversion () {
      for (let n of this.items) {
        if (!n.can) continue
        let name = n.name.split('-')
        if (name[0] === 'create') name.push(name.shift())
        this.index.push({ name: name.join(' '), path: n.path })
      }
    }
  },
  created () {
    this.$router.options.routes.forEach(route => {
      if (route.children) {
        route.children.forEach(child => {
          if (child.meta && child.meta.permission) {
            this.items.push({
              name: child.name,
              path: child.path || route.path,
              can: this.can(child.meta.permission)
            })
          }
        })
      } else if (route.meta && route.meta.permission) {
        this.items.push({
          name: route.name,
          path: route.path,
          can: this.can(route.meta.permission)
        })
      }
    })
  },
  mounted () {
    this.nameConversion()
  }
}
</script>
