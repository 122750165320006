var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},on:{"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Template Editor")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.saveTemplate}},[_vm._v(" "+_vm._s(_vm.isExisting ? 'Update' : 'Create')+" ")])],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveTemplate($event)}}},[_c('v-card',{staticClass:"mt-8",attrs:{"flat":"","min-width":"600"}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name*","hint":"A helpful name used for selecting a template.","error-messages":errors},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Text*","rows":"20","hint":"This is the template body that is inserted when used.","error-messages":errors},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.scopes,"loading":_vm.loadingScopes,"item-text":"text","item-value":"value","label":"Template Scope*","hint":"This determines where the templates appear and who can use them.","error-messages":errors},model:{value:(_vm.scope),callback:function ($$v) {_vm.scope=$$v},expression:"scope"}})]}}],null,true)})],1),_c('v-card-actions',[(_vm.isExisting)?_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":_vm.deleteTemplate}},[_vm._v(" Delete ")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"type":"submit","color":"primary"}},[_vm._v(" "+_vm._s(_vm.isExisting ? 'Update' : 'Create')+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }