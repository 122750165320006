<template>
  <v-btn
    text
    @click="$emit('input', !value)"
  >
    <slot />
    <v-icon
      class="pl-1"
      :color="value ? 'green' : ''"
      small
    >
      {{ value ? 'mdi-checkbox-blank-circle' : 'mdi-checkbox-blank-circle-outline' }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'EventScopeButton',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  }
}
</script>
