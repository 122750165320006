<template>
  <v-container>
    <v-card class="my-4">
      <card-list-item
        title="Groups"
        class="caption"
      >
        <v-list dense>
          <v-list-item
            v-for="role in getRoles"
            :key="role"
          >
            {{ role.toUpperCase() }}
          </v-list-item>
        </v-list>
      </card-list-item>
    </v-card>
    <v-spacer />
    <user-memberships
      :user="currentUser"
    />
  </v-container>
</template>

<script>
import CardListItem from '@/base/components/CardListItem'
import { mapGetters } from 'vuex'
import UserMemberships from '@/views/UserProfile/components/UserMemberships.vue'

export default {
  name: 'UserProfile',
  components: {
    UserMemberships,
    CardListItem
  },
  data: () => ({
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Requires', value: 'requires' }
    ]
  }),
  computed: {
    ...mapGetters([
      'currentUser',
      'roles'
    ]),
    ...mapGetters('divisionStore', ['divisions']),
    division () {
      return this.divisions.find(d => d.code === this.currentUser.division_code)
    },
    getRoles () {
      return this.roles.map(role => role.replace(/-/g, ' ')).sort()
    }
  },
  watch: {
    '$vuetify.theme.dark' (setting) {
      this.$store.dispatch('updateUser', { dark_theme: setting })
    }
  },
  mounted () {
    this.$store.dispatch('divisionStore/fetchDivisions')
  }
}
</script>
