<template>
  <unauthorized
    :authorized="$store.getters.can('view-cut-plans')"
    message="Unauthorized to view cut plans"
  >
    <v-container>
      <div v-if="error">
        {{ error }}
      </div>
      <div v-else-if="loading">
        loading...
      </div>
      <div v-else>
        <base-title>
          Cut Plan
        </base-title>
        <lot-summary-dense
          :lot="lot"
          @updated="loadLotData"
        />
      </div>
    </v-container>
  </unauthorized>
</template>

<script>
import axios from 'axios'
import LotSummaryDense from '@/views/Inventory/CutPlans/components/LotSummaryDense'
import { mapMutations } from 'vuex'

export default {
  name: 'CutPlanView',
  components: { LotSummaryDense },
  data: () => ({
    error: null,
    loading: true,
    lot: null
  }),
  methods: {
    ...mapMutations('cutPlanStore', [
      'setPurchaseList',
      'setLotList',
      'setAllocations'
    ]),
    async loadLotData () {
      this.error = null
      this.loading = true
      this.lot = null
      const response = await axios.get(`/api/purchase_lots/cut_plan/?lot=${this.$route.params.id}`)
      if (response.status === 200) {
        this.lot = response.data.lots[0]
        const data = response.data
        this.setPurchaseList(data.purchases)
        this.setLotList(data.lots)
        this.setAllocations(data.allocations)
      } else {
        this.error = 'Unable to retrieve cut plan.'
      }
      this.loading = false
    }
  },
  mounted () {
    if (!this.$store.getters.can('view-cut-plans')) return
    this.loadLotData()
  }
}
</script>
