<template>
  <unauthorized
    :authorized="$store.getters.can('view-cut-plans')"
    message="Unauthorized to view cut plans"
  >
    <div>
      <base-title>
        Shredding Operations
        <template v-slot:subtitle>
          {{ $refs.calendar ? $refs.calendar.title : '' }}
        </template>
      </base-title>
      <div>
        <v-row>
          <v-col
            cols="12"
          >
            <calendar-view-select
              class="mb-4"
              v-model="type"
              :prev="() => $refs.calendar.prev()"
              :next="() => $refs.calendar.next()"
              @dayFocus="focus = $event"
            />
            <div
              class="text-sm-body-2"
              v-if="$store.getters.can('edit-cut-plans')"
            >
              <em>Click on a day in the month view to add a note</em>
              <info-tooltip>
                Requires edit-cut-plans permission
              </info-tooltip>
            </div>
            <v-sheet height="700">
              <!--key added as a bug fix to force re-drawing-->
              <v-calendar
                :key="events.length"
                ref="calendar"
                v-model="focus"
                :type="type"
                :events="events"
                :event-color="x => x.color"
                :event-text-color="e => getSafeColor(e.color)"
                class="font-weight-bold"
                @click:event="showEvent"
                @click:more="viewDay"
                @click:date="viewDay"
                @change="fetchEvents"
                @click:day="openAddEventDialog"
              />
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                offset-x
                min-width="300"
                max-width="600"
              >
                <event-card
                  v-model="selectedEvent"
                  @deleteNote="deleteNote"
                  @close="selectedOpen = false"
                >
                  {{ selectedEvent.notes }}
                </event-card>
              </v-menu>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </div>
    <div>
      <shredding-operations-table
        :lots="lotList"
      />
    </div>
    <edit-dialog
      v-if="dialogEvent"
      v-model="dialogVisible"
      headline-text="Add Note"
      accept-text="create note"
      @accept="saveNote(noteText, dialogEvent.date)"
    >
      <p>
        Add a note to {{ dialogEvent.date | formatDate }}
      </p>
      <v-text-field
        v-model="noteText"
      />
    </edit-dialog>
  </unauthorized>
</template>

<script>
import CalendarViewSelect from '@/components/Calendar/CalenderViewSelect'
import axios from 'axios'
import EventCard from '@/views/Calendar/components/EventCard'
import ShreddingOperationsTable from '@/views/Inventory/ShreddingOperations/ShreddingOperationsTable'
import moment from 'moment'
import { deleteShreddingNote, getShreddingNotesInRange, postShreddingNote } from '@/requests/shreddingNotes'
import { getSafeColor } from '@/helpers/utils'

export default {
  name: 'ShreddingOperationsView',
  components: { ShreddingOperationsTable, EventCard, CalendarViewSelect },
  data: () => ({
    noteText: '',
    dialogEvent: null,
    dialogVisible: false,
    selectedOpen: false,
    selectedElement: null,
    selectedEvent: false,
    type: 'month',
    focus: '',
    calendarRange: null,
    lotList: [],
    events: [],
    fields: [
      'id',
      'name',
      'status',
      'cut_plan_notes',
      'cut_plan_color',
      'get_status_display',
      'shredding_started_date_time',
      'shredding_completed_date_time'
    ]
  }),
  methods: {
    deleteNote (id) {
      deleteShreddingNote(id).then(() => {
        this.events = this.events.filter(e => !(e.type === 'note' && e.id === id))
        this.selectedOpen = false
      })
    },
    getSafeColor,
    saveNote (noteText, date) {
      postShreddingNote(noteText, date).then(response => {
        this.events.push(this.formatNote(response.data))
        this.$store.commit('setSnackbarSuccess', 'Note Saved')
      })
    },
    openAddEventDialog (event) {
      this.dialogEvent = event
      // Handle the click event and open the dialog/form to add a new event
      this.noteText = ''
      this.dialogVisible = true
      // You can access the event details from the 'event' parameter if needed
    },
    lotToEvent (lot) {
      const o = {
        type: 'lot',
        name: lot.name,
        notes: lot.cut_plan_notes,
        color: lot.cut_plan_color,
        timed: true,
        to: { name: 'cut-plan-view', params: { id: lot.id } }
      }
      if (lot.shredding_started_date_time) {
        o['start'] = new Date(lot.shredding_started_date_time)
      }
      if (lot.shredding_completed_date_time) {
        o['end'] = new Date(lot.shredding_completed_date_time)
      }
      return o
    },
    fetchByStartDate (startDate, endDate, fields) {
      return axios.get('/api/lots/', {
        params: {
          'shredding_started_date_time__gte': startDate,
          'shredding_started_date_time__lte': endDate,
          'page_size': 0,
          fields
        }
      })
    },
    fetchByCompleteDate (startDate, endDate, fields) {
      return axios.get('/api/lots/', {
        params: {
          'shredding_completed_date_time__gte': startDate,
          'shredding_completed_date_time__lte': endDate,
          'page_size': 0,
          fields
        }
      })
    },
    addOneDay (dateString) {
      return moment(dateString, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
    },
    formatNote (note) {
      return {
        type: 'note',
        id: note.id,
        name: note.note,
        notes: note.note,
        timed: true,
        start: note.date,
        end: note.date
      }
    },
    async fetchNoteEvents (startDate, endDate) {
      const response = await getShreddingNotesInRange(startDate, endDate)
      return response.data.map(this.formatNote)
    },
    async fetchLotEvents (startDate, endDate) {
      const lots = {}
      const fields = this.fields.join(',')
      const responses = await Promise.all([
        this.fetchByStartDate(startDate, endDate, fields),
        this.fetchByCompleteDate(startDate, endDate, fields)
      ])

      responses.forEach(response => {
        response.data.forEach(lot => {
          lots[lot.id] = lot
        })
      })
      this.lotList = Object.values(lots)
      return this.lotList.map(this.lotToEvent)
    },
    async fetchEvents (calendarRange) {
      this.calendarRange = calendarRange
      if (!calendarRange) {
        this.$store.commit('setSnackbarError', 'Unable to get dates for calendar update.')
        return
      }
      const startDate = calendarRange.start.date
      const endDateA = calendarRange.end.date
      // adds an extra day to get around the datetime field looking for things before today for end date
      const endDate = this.addOneDay(endDateA)

      const lotEvents = await this.fetchLotEvents(startDate, endDate)
      const noteEvents = await this.fetchNoteEvents(startDate, endDate)

      this.events = [...lotEvents, ...noteEvents]
    },
    // change view to specific day
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    // set the selected event so it can be shown // maybe even a pop up and fetch?
    showEvent ({ nativeEvent, event }) {
      nativeEvent.stopPropagation()
      this.selectedElement = nativeEvent.target
      this.selectedEvent = event
      setTimeout(() => {
        this.selectedOpen = true
      }, 10)
    }
  }
}
</script>
