<template>
  <div>
    <div v-if="show">
      <div :class="{ 'd-inline-block mr-6': $vuetify.breakpoint.mdAndUp }">
        <h4 class="caption">
          Shredding Started
        </h4>
        <p class="body-1">
          {{ shredDateTimeFormatted(lot.shredding_started_date_time) }}
        </p>
      </div>
      <div :class="{ 'd-inline-block mr-6': $vuetify.breakpoint.mdAndUp }">
        <h4 class="caption">
          Shredding Finished
        </h4>
        <p class="body-1">
          {{ shredDateTimeFormatted(lot.shredding_completed_date_time) }}
        </p>
      </div>
      <div :class="{ 'd-inline-block': $vuetify.breakpoint.mdAndUp }">
        <h4 class="caption">
          Shredding Finalized
        </h4>
        <p class="body-1">
          {{ (lot.shredding_finalized) ? 'Yes' : 'No' }}
        </p>
      </div>
    </div>
    <div v-else>
      No shredding info
    </div>
  </div>
</template>

<script>
export default {
  name: 'CutPlanShreddingInfo',
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  computed: {
    show () {
      return this.lot.shredding_started_date_time ||
        this.lot.shredding_completed_date_time ||
        this.lot.shredding_finalized
    }
  },
  methods: {
    shredDateTimeFormatted (shredDateTime) {
      if (!shredDateTime) return '--'
      const fmt = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
      return new Date(shredDateTime).toLocaleDateString('en-us', fmt)
    }
  }
}
</script>
