<template>
  <v-container v-if="inventory">
    <url-breadcrumbs
      v-if="inventory"
      :items="[
        ['inventory list', { name: 'inventory-list' }],
        inventory.url
      ]"
    />
    <v-alert
      v-if="alertMessage"
      outlined
      dense
      color="warning"
    >
      {{ alertMessage }}
    </v-alert>
    <v-row v-if="!loading">
      <base-title>
        {{ `Inventory ${inventory.name}` }}
      </base-title>
      <v-col class="text-right">
        <v-btn
          v-if="canEdit && this.$store.getters.can('update-hedge')"
          dark
          right
          @click="editDialog = !editDialog"
        >
          edit
        </v-btn>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="loading"
      type="card"
    />
    <inv-details-section
      v-else
      :inventory="inventory"
      :lot-types="lotTypes"
    />
    <br>
    <v-skeleton-loader
      v-if="loading"
      type="table"
    />
    <inventory-lines-section
      v-else
      :can-add-line="canEdit && this.$store.getters.can('update-inventory')"
      :container-types="containerTypes"
      :inventory-id="inventory.id"
      :inventory-lines="inventory.inventoryline_set"
      @refetchRequirements="fetchRequirements($route.params.id)"
      @linesUpdated="fetchRequirements($route.params.id)"
    />
    <edit-dialog
      v-if="$store.getters.can('update-inventory')"
      v-model="editDialog"
      headline-text="Edit Inventory"
      :inputs-valid="valid"
      @accept="saveInventory"
    >
      <v-form
        v-model="valid"
        @submit.prevent=""
      >
        <v-select
          label="Division"
          v-model="divisionId"
          :items="divisions"
        />
        <v-text-field
          label="Name"
          v-model="name"
          :counter="55"
          :rules="[x => x.length <= 55 || 'max length is 55 characters']"
        />
        <v-select
          label="Location"
          v-model="location"
          :items="warehouseLocations"
        />
        <v-textarea
          label="Lot Notes"
          v-model="notes"
          :counter="280"
          :rules="[x => x.length <= 280 || 'max length is 280 characters']"
        />
      </v-form>
    </edit-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import InvDetailsSection from './components/InvDetailsSection'
import InventoryLinesSection from './components/InventoryLinesSection.vue'
import axios from 'axios'

export default {
  name: 'Inventory',
  components: {
    InvDetailsSection,
    InventoryLinesSection
  },
  data: () => ({
    loading: true,
    valid: false, // no fields currently need validation
    editDialog: false,
    name: null,
    notes: null,
    divisionId: null,
    location: null,
    warehouseLocationsBase: [],
    divisions: []
  }),
  computed: {
    ...mapGetters({
      inventory: 'inventoryStore/inventory',
      containerTypes: 'inventoryStore/containerTypes',
      lotTypes: 'lotStore/lotTypes'
    }),
    alertMessage () {
      if (this.inventory && !this.inventory.create_inventory_boxes) {
        return 'Lines entered below will not be reflected in "inventory"'
      }
      if (!this.location) {
        return 'Please select a location before adding boxes'
      }
      return ''
    },
    canEdit () {
      return this.inventory.allow_line_edits
    },
    warehouseLocations () {
      if (!this.inventory.location) return this.warehouseLocationsBase
      const exists = this.warehouseLocationsBase.find(l => l.url === this.inventory.location)
      if (exists) return this.warehouseLocationsBase
      return [...this.warehouseLocationsBase, { text: this.inventory.location_name, value: this.inventory.location }]
    }
  },
  watch: {
    editDialog (value) {
      if (value) {
        this.fetchOptions()
      }
    }
  },
  methods: {
    ...mapActions({
      fetchRequirements: 'inventoryStore/fetchInventory'
    }),
    async saveInventory () {
      const payload = {
        name: this.name,
        notes: this.notes,
        division_id: this.divisionId,
        location: this.location
      }
      await axios.patch(this.inventory.url, payload)
      await this.fetchRequirements(this.$route.params.id)
      // save the inventory
      this.editDialog = false
    },
    init () {
      this.name = this.inventory.name
      this.notes = this.inventory.notes
      this.divisionId = this.inventory.division.id
      this.location = this.inventory.location
    },
    fetchOptions () {
      axios.get('/api/divisions/?page_size=0&fields=name,id').then(response => {
        this.divisions = response.data.map(d => ({ text: d.name, value: d.id }))
      })
      axios.get('/api/divisions/?page_size=0&is_inactive=false').then(response => {
        this.warehouseLocationsBase = response.data.reduce((c, d) => {
          if (d.logistics_location) {
            c.push({ text: d.logistics_location.name, value: d.logistics_location.url })
          }
          return c
        }, [])
      })
    }
  },
  async mounted () {
    await Promise.all([
      this.fetchRequirements(this.$route.params.id)
    ])
    this.init()
    this.loading = false
  }
}
</script>
