<template>
  <v-dialog
    :value="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @input="$emit('input', $event)"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="$emit('input', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Template Editor</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="saveTemplate"
          >
            {{ isExisting ? 'Update' : 'Create' }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="d-flex justify-center">
        <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
        >
          <v-form @submit.stop.prevent="saveTemplate">
            <v-card
              class="mt-8"
              flat
              min-width="600"
            >
              <v-card-text>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    v-model="name"
                    label="Name*"
                    hint="A helpful name used for selecting a template."
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    v-model="text"
                    label="Text*"
                    rows="20"
                    hint="This is the template body that is inserted when used."
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="scope"
                    :items="scopes"
                    :loading="loadingScopes"
                    item-text="text"
                    item-value="value"
                    label="Template Scope*"
                    hint="This determines where the templates appear and who can use them."
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  v-if="isExisting"
                  color="accent"
                  text
                  @click="deleteTemplate"
                >
                  Delete
                </v-btn>
                <v-spacer />
                <v-btn
                  text
                  @click="$emit('input', false)"
                >
                  Cancel
                </v-btn>
                <v-btn
                  type="submit"
                  color="primary"
                >
                  {{ isExisting ? 'Update' : 'Create' }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </ValidationObserver>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'

export default {
  name: 'TextTemplateEditDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    template: {
      type: Object,
      default: null,
      required: false
    },
    defaultScope: {
      type: String,
      default: null,
      required: false
    }
  },
  data: () => ({
    name: '',
    text: '',
    scope: '',
    scopes: [],
    loadingScopes: false
  }),
  computed: {
    isExisting () {
      return this.template && this.template.id
    }
  },
  watch: {
    value (opened) {
      if (opened) {
        this.name = this.template.name
        this.text = this.template.text
        this.scope = this.template.scope
        this.fetchScopes()
      }
    }
  },
  methods: {
    async deleteTemplate () {
      try {
        if (this.template.id) {
          await axios.delete(`/api/text_templates/${this.template.id}/`)
        }
        this.$emit('input', false)
      } catch (err) {
        this.$store.commit('setSnackbarError', 'Failed to delete template.')
      }
    },
    async saveTemplate () {
      if (!this.name || !this.text || !this.scope) {
        this.$store.commit(
          'setSnackbarError',
          'The form is not valid. Please correct the errors and try again.'
        )
        return
      }
      const t = this.template || Object()
      const changes = { name: this.name, text: this.text, scope: this.scope }
      const template = Object.assign({}, t, changes)
      try {
        if (template.id) {
          await axios.patch(`/api/text_templates/${template.id}/`, template)
        } else {
          await axios.post('/api/text_templates/', template)
        }
        this.$emit('input', false)
      } catch (err) {
        this.$store.commit('setSnackbarError', 'Failed to save template.')
      }
    },
    async fetchScopes () {
      this.loadingScopes = true
      const response = await axios.get('/api/text_templates/scopes/')
      if (response.status === 200) {
        this.scopes = response.data.map(scope => ({ text: scope[1], value: scope[0] }))
        if (!this.scope && this.defaultScope) this.scope = this.defaultScope
      } else {
        this.$store.commit('setSnackbarError', 'Unable to fetch scopes')
      }
      this.loadingScopes = false
    }
  }
}
</script>
