import DataTablePdfMakeAdaptor from '@/helpers/pdfMake/DataTablePdfMakeAdaptor'

/**
 * @getTable Returns pdfmake-compatible table data for lot purchase summaries
 *
 * Generates pdfmake-compatible table data for lot purchase summaries
 * @param { Array } lotTitle Cut Plan Lot Title
 * @param { Array } lotNotes Cut Plan Lot Notes
 * @param { Array } headers lot purchases table headers formatted for v-data-table headers prop
 * @param { Array } items lot purchases data formatted for v-data-table items prop
 * @constructor
 */
class SummariesTable {
  constructor (headers, table, lotTitle, lotNotes) {
    this.headers = headers
    this.table = table
    this.lotTitle = lotTitle
    this.lotNotes = lotNotes
    this.widths = [40, 75, 23, 155, 50, 50, 50, 50, 50, 50, 50, 50]
  }
  /**
   * Returns a DocDef compatible Table
   */
  getTable () {
    return [
      {
        columns: [
          {
            width: 500,
            stack: [
              {
                style: 'subheading',
                margin: [0, 0, 0, 10],
                text: this.lotTitle
              },
              {
                margin: [0, 0, 0, 10],
                text: this.lotNotes
              }
            ]
          },
          {
            style: 'subheadingRight',
            stack: [
              { text: '* Check-In has Intake' },
              {
                margin: [0, 0, 3, 0],
                text: 'x Included in this Lot'
              }
            ]
          }
        ]
      },
      (new DataTablePdfMakeAdaptor(this.headers, this.table, this.widths)).getTable()
    ]
  }
}

const lotPurchasesSummary = (...args) => (new SummariesTable(...args)).getTable()

export { lotPurchasesSummary }
