<!--Expected to be inside a v-card-->
<template>
  <v-card
    @click="dialog = !dialog"
  >
    <v-card-text>
      <div class="text-h6">
        {{ lot.name }}
        <v-icon :color="lot.cut_plan_color">
          mdi-square
        </v-icon>
      </div>
      {{ lot.lot_type_display }}
      <p v-if="lot.cut_plan_notes">
        <strong>
          {{ lot.cut_plan_notes }}
        </strong>
      </p>
      <cut-plan-shredding-info :lot="lot" />
    </v-card-text>
    <v-card-actions>
      <ls-purchase-table-dialog :lot="lot" />
      <v-spacer />
      <v-btn
        :disabled="!lot.tally_sheet"
        :href="lot.tally_sheet"
        target="_blank"
        color="primary"
        small
        class="mr-2"
      >
        View Tally Sheet
      </v-btn>
      <cut-plan-edit-dialog
        :lot="lot"
        @updated="$emit('updated')"
      />
    </v-card-actions>
    <edit-dialog
      :headline-text="this.lot.name"
      v-model="dialog"
      @accept="updateText"
    >
      <div
        class="hide-color-controls"
      >
        <h3>Lot Color</h3>
        <v-color-picker
          v-model="color"
          disabled
          hide-mode-switch
          hide-sliders
          hide-inputs
          hide-canvas
          mode="hexa"
          :swatches="getColorSwatches.filter(c => !c.inactive).map(c => c.hex)"
          show-swatches
        />
        <p>
          <em v-if="color && selectedColorName">
            {{ selectedColorName }} {{ color }}
          </em>
        </p>
      </div>
      <text-template-select
        scope-code="CP"
        :current-value="notes"
        @change="notes = $event"
      />
      <v-textarea
        rows="10"
        v-model="notes"
        label="Cut Plan Notes"
      />
      <template v-slot:actionBtn>
        <v-btn
          @click="skipCutting"
        >
          Mark All Cut
        </v-btn>
      </template>
    </edit-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TextTemplateSelect from '@/components/textTemplates/TextTemplateSelect'
import LsPurchaseTableDialog from '@/views/Inventory/CutPlans/components/LsPurchaseTableDialog'
import CutPlanShreddingInfo from '@/views/Inventory/CutPlans/components/CutPlanShreddingInfo'
import CutPlanEditDialog from '@/views/Inventory/CutPlans/components/CutPlanEditDialog'
import axios from 'axios'

export default {
  name: 'LotSummary',
  components: { CutPlanEditDialog, CutPlanShreddingInfo, LsPurchaseTableDialog, TextTemplateSelect },
  props: {
    lot: {
      type: Object,
      required: true,
      notes: ''
    }
  },
  data: () => ({
    dialog: false,
    color: '#EAEEE9',
    notes: ''
  }),
  watch: {
    dialog: {
      handler (opened) {
        if (opened) {
          this.notes = this.lot.cut_plan_notes
          if (this.lot.cut_plan_color) {
            this.color = this.lot.cut_plan_color
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters('cutPlanStore', [ 'getColorSwatches' ]),
    selectedColorName () {
      try {
        if (this.color) {
          const c = this.getColorSwatches.find(x => x.hex[0].toUpperCase() === this.color)
          if (c) {
            return c.name
          }
        }
      } catch {
        // just return empty on any error
        return ''
      }
      return ''
    }
  },
  methods: {
    ...mapActions('cutPlanStore', [
      'updateLot'
    ]),
    async skipCutting () {
      if (confirm(`Are you sure you would like to mark ${this.lot.name} and attached purchases as CUT?`)) {
        const status = this.$store.getters['lotStore/lotStatuses'].find(x => x.value === 'CT')
        if (!status) {
          this.$store.commit('setSnackbarWarning', 'Unable to retrieve status')
          return
        }
        try {
          await axios.post(this.lot.url + 'cut_purchases/')
          await this.updateLot({
            lotUrl: this.lot.url,
            payload: { status: status['value'] }
          })
          this.$store.commit('setSnackbarSuccess', 'Lot and Purchases now marked cut')
        } catch {
          this.$store.commit('setSnackbarError', 'Attempt to update lot and purchases resulted in an error')
        }
        this.dialog = false
      }
    },
    updateText () {
      this.updateLot({
        lotUrl: this.lot.url,
        payload: { cut_plan_notes: this.notes, cut_plan_color: this.color }
      })
    }
  }
}
</script>

<style>
.hide-color-controls .v-color-picker__controls {
  display: none !important;
}
</style>
