<template>
  <v-data-table
    multi-sort
    :headers="headers"
    :items="lots"
    hide-default-footer
    mobile-breakpoint="0"
  >
    <template v-slot:item.cut_plan_color="{ item }">
      <cut-plan-view-button
        :id="item.id"
        :color="item.cut_plan_color"
      />
    </template>
    <template v-slot:item.name="{ item }">
      <router-link-id
        v-if="$store.getters.can('view-partial-lots')"
        :id="item.id"
        name="lot"
      >
        <v-btn small>
          {{ item.name }}
        </v-btn>
      </router-link-id>
      <div
        class="text-no-wrap"
        v-else
      >
        {{ item.name }}
      </div>
    </template>
    <template v-slot:item.status="{ item }">
      {{ item.get_status_display }}
    </template>
    <template v-slot:item.code="{ item }">
      <span style="white-space: nowrap;">
        {{ item.code }}
      </span>
    </template>
    <template v-slot:item.shredding_started_date_time="{ item }">
      <span class="text-no-wrap caption">
        {{ fmtDate(item.shredding_started_date_time) }}
        <br>
        {{ fmtTime(item.shredding_started_date_time) }}
      </span>
    </template>
    <template v-slot:item.shredding_completed_date_time="{ item }">
      <span class="text-no-wrap caption">
        {{ fmtDate(item.shredding_completed_date_time) }}
        <br>
        {{ fmtTime(item.shredding_completed_date_time) }}
      </span>
    </template>
  </v-data-table>
</template>

<script>
import CutPlanViewButton from '@/components/CutPlanViewButton'
export default {
  name: 'ShreddingOperationsTable',
  components: { CutPlanViewButton },
  props: {
    lots: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Cut Plan Notes', value: 'cut_plan_notes' },
        { text: 'Status', value: 'status' },
        { text: 'Started', value: 'shredding_started_date_time' },
        { text: 'Completed', value: 'shredding_completed_date_time' },
        { text: 'Cut Plan', value: 'cut_plan_color' }
      ]
    }
  },
  methods: {
    fmtDate (dateTime) {
      if (!dateTime) return ''
      return (new Date(dateTime)).toLocaleDateString()
    },
    fmtTime (dateTime) {
      if (!dateTime) return ''
      return (new Date(dateTime)).toLocaleTimeString()
    }
  }
}
</script>
