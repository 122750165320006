<template>
  <v-card>
    <v-card-title class="caption">
      Notification Group Memberships
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="[
          { text: 'Group', value: 'notification_group__name' },
          { text: 'Description', value: 'notification_group__description' },
          { text: 'Email', value: 'subscribed_email', align: 'right' }
        ]"
        :items="memberships"
      >
        <template v-slot:item.subscribed_email="{ item }">
          <div class="d-flex align-end flex-column">
            <v-checkbox
              :input-value="item.subscribed_email"
              color="deep-purple accent-4"
              @change="toggleMembership(item.id)"
            />
          </div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  name: 'UserMemberships',
  data: () => ({
    memberships: []
  }),
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    async fetchMemberships () {
      const response = await axios.get('api/notification_memberships/', { params: { page_size: 0 } })
      this.memberships = response.data.sort((a, b) => a.notification_group__name.localeCompare(b.notification_group__name))
    },
    async toggleMembership (id) {
      // get the current value
      // change the value and commit it
      let err = ''
      const index = this.memberships.findIndex(m => m.id === id)
      if (index !== -1) {
        const payload = { subscribed_email: !this.memberships[index].subscribed_email }
        const response = await axios.patch(`api/notification_memberships/${id}/`, payload)
        if (response.status !== 200) err = 'bad request'
      } else {
        err = 'not found'
      }
      if (err) this.$store.commit('setSnackbarError', `Unable to update membership (${err})`)
    }
  },
  async mounted () {
    await this.fetchMemberships()
  }
}
</script>
