<template>
  <unauthorized
    :authorized="$store.getters.can('edit-cut-plans')"
    message="Unauthorized to edit cut plans"
  >
    <v-dialog
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      :value="value"
      @input="$emit('input', $event)"
    >
      <v-card>
        <v-toolbar
          dense
          dark
          color="primary"
        >
          <v-toolbar-title>
            Edit Allocations for selected Check Ins
          </v-toolbar-title>
          <v-spacer />
          <help-dialog>
            <template v-slot:activator="{ on }">
              <v-btn
                text
                @click="on"
              >
                Help
              </v-btn>
            </template>
            <p>
              Select the Material Types you wish to allocate on the left. Then click on the related lot on the right to
              apply the selected allocations.
            </p>
            <p>
              When all allocations are applied the dialog will close automatically.
            </p>
          </help-dialog>
          <v-toolbar-items>
            <v-btn
              dark
              icon
              @click="$emit('input', false)"
            >
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="ma-0">
          <v-col>
            <v-list>
              <div
                v-for="(purchase, key) in purchases"
                :key="key + '-purchase'"
              >
                <v-list-item
                  dense
                  v-for="checkIn in purchase.check_ins_details"
                  :key="checkIn.id"
                >
                  <v-list-item-content>
                    <strong>
                      {{ checkIn.id }} - {{ checkIn.yard_name }}
                      <h5 class="red--text text--darken-3">
                        {{ checkIn.current_location }}
                      </h5>
                    </strong>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list>
          </v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col
            cols="12"
            sm="6"
          >
            <v-list
              dense
            >
              <v-list-item>
                <v-list-item-content>
                  Select All
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    v-if="selected.length !== converterTypes.length"
                    @click="selected = converterTypes.map(x => x.value)"
                    icon
                  >
                    <v-icon
                      class="pl-2"
                    >
                      mdi-checkbox-blank-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    icon
                    color="success"
                    @click="selected = []"
                  >
                    <v-icon
                      class="pl-2"
                    >
                      mdi-checkbox-marked-outline
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                v-for="(cvt, index) in converterTypes"
                :key="index"
                class="mb-2 elevation-2"
                @click="toggleSelected(cvt.value)"
              >
                <v-list-item-content>
                  {{ cvt.text }}
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    elevation="0"
                    small
                    icon
                    :loading="selected.includes(cvt.value) && lotLoadingId !== null"
                    :color="selected.includes(cvt.value) ? 'success' : ''"
                  >
                    <v-icon
                      v-show="selected.includes(cvt.value)"
                    >
                      mdi-check-bold
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-list-item>
              <v-spacer />
              <v-list-item-action>
                <v-btn
                  :disabled="!selected.length"
                  color="accent"
                  text
                  @click="removeAllocations"
                >
                  Remove checkin/lot allocations
                  <v-icon
                    class="ml-2"
                    small
                  >
                    mdi-delete-outline
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item
              v-for="(lot, index) in getLots"
              :key="index"
              class="mb-2 elevation-2"
              @click="setAllocations(lot.id)"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ lot.name }}
                  <v-icon :color="lot.cut_plan_color">
                    mdi-square
                  </v-icon>
                </v-list-item-title>
                {{ lot.cut_plan_notes }}
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </unauthorized>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MultiCutPlanAllocation',
  props: {
    value: {
      type: Boolean,
      required: false
    },
    purchases: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    // todo this is temp code
    purchase: null,

    lotLoadingId: null,
    selected: [],
    // holds the lot data
    allocationDetails: {}
  }),
  watch: {
    value: {
      immediate: true,
      handler (opened) {
        if (!opened) return
        const allocated = this.allocations.map(al => al.converter_type)
        this.selected = this.converterTypes.map(cv => cv.value).filter(type => !allocated.includes(type))
      }
    }
  },
  computed: {
    ...mapGetters('cutPlanStore', [
      'getLots',
      'getLotCache',
      'getAllocations',
      'getAllocationsIndexPurchaseType'
    ]),
    ...mapGetters({
      converterTypes: 'getConverterTypes'
    }),
    /**
     * Gets the curated list of allocations
     * @returns {any[]}
     */
    allocations () {
      const pUrls = this.purchases.map(p => p.url)
      const allocations = this.$store.getters['cutPlanStore/getAllocations']
      return Object.values(allocations).filter(e => pUrls.includes(e.purchase))
    }
  },
  methods: {
    ...mapActions('cutPlanStore', [
      'dynamicAllocation',
      'fetchLot',
      'removeAllocation'
    ]),
    /**
     * Adds and removes an item from the selected types
     * @param cvtValue
     */
    toggleSelected (cvtValue) {
      if (this.selected.includes(cvtValue)) {
        this.selected = this.selected.filter(s => s !== cvtValue)
      } else {
        this.selected = [...this.selected, cvtValue]
      }
    },
    /**
     * Sets allocations. Based on Allocation Lots (if it exists it updates, else it creates)
     */
    async setAllocations (lotId) {
      if (this.selected.length === 0) {
        this.$store.commit('setSnackbarWarning', 'No Types selected')
        return
      }
      this.lotLoadingId = lotId

      // This is the modification loop required to apply changes to all selected purchases
      const changes = this.purchases.map(purchase => {
        return this.selected.map(type => {
          return { purchase_id: purchase.id, converter_type: type, lot_id: lotId }
        })
      })

      const mergedChanges = [].concat.apply([], changes)

      try {
        await this.dynamicAllocation(mergedChanges)
        this.selected = []
        this.$store.commit('setSnackbarSuccess', 'Allocations Applied')
      } catch (e) {
        this.$store.commit('setSnackbarError', 'Failed to apply Allocations')
      }

      this.lotLoadingId = null
      // this component does not auto-close
    },
    removeAllocations () {
      if (confirm('Are you sure you want remove selected allocations for selected check ins?')) {
        const f = this.allocations.filter(allo => this.selected.includes(allo.converter_type))
        f.forEach(allocation => this.removeAllocation(allocation.url))
        this.selected = []
      }
    }
  }
}
</script>
