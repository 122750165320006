<!--
Component that connects directly to the cut plan store for filtering LOTS and PURCHASES for selected entries
-->

<template>
  <v-autocomplete
    :value="getPurchaseFilters"
    @input="setPurchaseFilters"
    :items="getPurchases"
    :item-text="friendlyName"
    item-value="url"
    label="Filter by Purchase"
    multiple
    clearable
    persistent-hint
    hint="CheckIn #, Yard Name (Purchase #)"
  />
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'CutPlanPurchaseFilter',
  data: () => ({
    selected: []
  }),
  computed: {
    ...mapState('cutPlanStore', [
      'purchaseFilters'
    ]),
    ...mapGetters('cutPlanStore', [
      'getPurchases',
      'getPurchaseFilters'
    ])
  },
  methods: {
    ...mapMutations('cutPlanStore', [
      'setPurchaseFilters'
    ]),
    friendlyName (purchase) {
      if (!purchase.check_ins_details) return purchase.id
      let yards = []
      let ciIds = []
      purchase.check_ins_details.forEach(ci => {
        ciIds.push(ci.id)
        if (yards.includes(ci.yard_name)) return
        yards.push(ci.yard_name)
      })
      return `${ciIds.join(', ')} ${yards.join(', ')} (${purchase.id})`
    }
  }
}
</script>
