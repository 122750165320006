<!--Expected to be inside a v-card-->
<template>
  <v-card class="mb-4">
    <v-sheet
      :color="lot.cut_plan_color"
      width="100%"
      class="text-start pa-4"
      :class="friendlyTextColor"
    >
      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.xs ? 12 : 10"
          class="py-0"
        >
          <h3 class="title">
            <strong>
              {{ lot.name }}
              {{ lot.lot_type_display }}
            </strong>
          </h3>
          <p class="body-1 mb-0">
            {{ lot.cut_plan_notes }}
          </p>
        </v-col>
        <v-col
          :cols="$vuetify.breakpoint.xs ? 12 : 2"
          :class="[$vuetify.breakpoint.xs ? 'pb-0' : 'text-right py-0']"
          class="d-flex flex-column"
        >
          <div class="caption">
            Designation
          </div>
          <h4 class="mb-2">
            {{ lot.designation }}
          </h4>
          <div
            class="caption"
          >
            {{ lotColor.name }}
          </div>
        </v-col>
      </v-row>
    </v-sheet>
    <v-card-text>
      <ls-purchase-table :lot="lot" />
      <cut-plan-shredding-info
        class="ml-4 mt-4"
        :lot="lot"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :disabled="!lot.tally_sheet"
        :href="lot.tally_sheet"
        target="_blank"
        color="primary"
        small
        class="mr-2"
      >
        View Tally Sheet
      </v-btn>
      <cut-plan-edit-dialog
        :lot="lot"
        @updated="$emit('updated')"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import lotSummaryMixin from '@/views/Inventory/CutPlans/components/lotSummaryMixin'
import LsPurchaseTable from '@/views/Inventory/CutPlans/components/LsPurchaseTable'
import CutPlanShreddingInfo from '@/views/Inventory/CutPlans/components/CutPlanShreddingInfo'
import CutPlanEditDialog from '@/views/Inventory/CutPlans/components/CutPlanEditDialog'
import { getFriendlyTextClasses } from '@/helpers/utils.js'

export default {
  mixins: [lotSummaryMixin],
  name: 'LotSummaryDense',
  components: {
    CutPlanEditDialog,
    CutPlanShreddingInfo,
    LsPurchaseTable
  },
  computed: {
    friendlyTextColor () {
      return getFriendlyTextClasses(this.lot.cut_plan_color)
    }
  }
}
</script>
