import axios from 'axios'

export function deleteShreddingNote (id) {
  return axios.delete(`/api/shredding_notes/${id}/`)
}

export function patchShreddingNote (id, note) {
  const payload = { note }
  return axios.patch(`/api/shredding_notes/${id}/`, payload)
}

export function postShreddingNote (note, date) {
  const payload = {
    note,
    date
  }
  return axios.post('/api/shredding_notes/', payload)
}

export function getShreddingNotesInRange (startDate, endDate) {
  const params = {
    date__gte: startDate,
    date__lte: endDate,
    page_size: 0
  }
  return axios.get('/api/shredding_notes/', { params })
}
