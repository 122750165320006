var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.lots,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.cut_plan_color",fn:function(ref){
var item = ref.item;
return [_c('cut-plan-view-button',{attrs:{"id":item.id,"color":item.cut_plan_color}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(_vm.$store.getters.can('view-partial-lots'))?_c('router-link-id',{attrs:{"id":item.id,"name":"lot"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_status_display)+" ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.shredding_started_date_time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap caption"},[_vm._v(" "+_vm._s(_vm.fmtDate(item.shredding_started_date_time))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.fmtTime(item.shredding_started_date_time))+" ")])]}},{key:"item.shredding_completed_date_time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap caption"},[_vm._v(" "+_vm._s(_vm.fmtDate(item.shredding_completed_date_time))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.fmtTime(item.shredding_completed_date_time))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }